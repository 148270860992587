export default {
  newsletter: {
    subscribe: 'Newsletter Subscription',
    form_title: 'I wish to subscribe to the newsletter',
    name: 'First Name',
    surname: 'Last Name',
    accept_data: 'I accept the data protection regulation',
    submission_success: 'Congratulations, you have been subscribed!',
    submission_error: 'Sorry, something went wrong.',
    already_subscribed: 'This address is already subscribed.',
    validation: {
      required: 'This field is required',
      email: 'Please use a valid email address',
      accept_data: 'You must accept the privacy policy to subscribe.'
    },
    bosw: {
      extra_subscription_1: 'I also subscribe to the „Best of Swiss Apps“ newsletter.',
      extra_subscription_2: 'I also subscribe to the „Best of Swiss Software“ newsletter.'
    },
    bosa: {
      extra_subscription_1: 'I also subscribe to the „Best of Swiss Web“ newsletter.',
      extra_subscription_2: 'I also subscribe to the „Best of Swiss Software“ newsletter.'
    },
    boss: {
      extra_subscription_1: 'I also subscribe to the „Best of Swiss Web“ newsletter.',
      extra_subscription_2: 'I also subscribe to the „Best of Swiss Apps“ newsletter.'
    }
  },
  footer: {
    telephone: 'Telephone',
    media: 'Press Contact',
    privacy_link: '',
    terms_link: '',
    impressum_link: '',
  },
  timeline: {
    months: ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEPT', 'OCT', 'NOV', 'DEC']
  },
  filter: {
    all_years: 'All years',
    all_categories: 'All categories',
    all_awards: 'All awards',
    gold: 'Winner'
  },
  client: 'Client',
  contractor: 'Contractor',
  cookieConsent: 'This website uses cookies to optimize and offer a better service. By continuing to navigate on this website, you agree to the use of these cookies.'
}

import Vue from "vue";
import VueI18n from 'vue-i18n';
import fr from './i18n-fr';
import de from './i18n-de';
import en from './i18n-en';

Vue.use(VueI18n);

const messages = {
  fr: fr,
  de: de,
  en: en
}

let lang = getCurrentLanguage();

// Create VueI18n instance with options
const i18n = new VueI18n({
  locale: lang, // set locale
  fallbackLocale: 'de', // set fallback locale to de
  messages, // set locale messages
})

export function getLanguages() {
  let basename = sessionStorage.basename;
  if(basename === '/bestofswisssoftware')
      return process.env.VUE_APP_LANGUAGES_BOSS.split(' ');

  return process.env.VUE_APP_LANGUAGES.split(' ');
}

export function removeCurrentLanguage(string, currentLanguage) {
  return string.replace(new RegExp('/' + currentLanguage + '($|/)'), '/');
}

export function getCurrentLanguage() {
  const languages = getLanguages();

  for (let i = 0; i < languages.length; i++) {
    const language = languages[i];

    if (new RegExp('/' + language + '($|/)').test(window.location.pathname)) {
      return language;
    }
  }

  return languages[0];
}

export function changeLanguage(newLanguage) {
  const nodeName = process.env.VUE_APP_SITE_BASENAME;
  const languages = getLanguages();
  let pathname = window.location.pathname;
  const currentLanguage = getCurrentLanguage();
  pathname = removeCurrentLanguage(pathname, currentLanguage);

  if (languages[0] !== newLanguage) {
    if (pathname.indexOf(nodeName) > -1) {
      pathname = pathname.replace(new RegExp(nodeName), '/' + newLanguage + nodeName);
    } else {
      pathname = '/' + newLanguage + pathname;
    }
  }

  window.location.href = window.location.origin + pathname + window.location.search;
}

export default i18n;
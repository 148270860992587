<template>
  <div class="languages">
    <span
      v-for="language in languages"
      :key="language"
      :class="{active:currentLanguage === language}"
      @click="clickLanguage(language)">
      {{ language }}
    </span>
  </div>
</template>

<script>
  import {
    getLanguages,
    changeLanguage,
    getCurrentLanguage
  } from "../i18n/i18n";

  export default {
    name: 'LanguageSelector',
    data() {
      return {
        languages: getLanguages(),
        currentLanguage: getCurrentLanguage(),
        context: 'boss'
      }
    },
    mounted(){
      setTimeout(()=> {
        if(document.querySelector('#app').classList.contains('bosw'))
          this.context = 'bosw';
        else if(document.querySelector('#app').classList.contains('bosa'))
          this.context = 'bosa';
      }, 500);
    },
    methods: {
      clickLanguage: function(language) {
        changeLanguage(language);
      }
    }
  }
</script>
<style lang="scss" scoped>
  .languages {
    line-height:0.75;
    border:1px solid #7F7F7F;
    border-radius:20%/50%;
    height:rem(22px);

    span{
      display:inline-block;
      text-transform: uppercase;
      font-size: 0.75rem;
      font-weight:500;
      color:$grey;
      line-height:inherit;
      padding:$spacer/16 $spacer/20 $spacer/16 $spacer/20;
      cursor:pointer;
      transition: $transition-base;

      &:first-child{
        padding-left:$spacer/6;
      }

      &:last-child{
        padding-right:$spacer/6;
      }

      &.active,
      &:hover{
        color: $black;
      }
    }
  }

  @include media-breakpoint-down(sm){
    .languages {
      font-size:$base-size;
      line-height:0.45;
      height:rem(18px);

      span{
        display:inline-block;
        text-transform: uppercase;
        font-size: 0.55rem;
        font-weight:500;
        color:$grey;
        line-height:inherit;
        padding:$spacer/16 $spacer/20 $spacer/16 $spacer/20;
        cursor:pointer;
        transition: $transition-base;

        &:first-child{
          padding-left:$spacer/6;
        }

        &:last-child{
          padding-right:$spacer/6;
        }

        &.active,
        &:hover{
          color: $black;
        }
      }
    }
  }
</style>